import * as YahooActions from '../../Actions/Yahoo';
import * as AppActions from '../../AppActions';

const pageSizeOptions = [10, 30, 50];
const sortOptions = [
  {name: '價錢高到低', value: '-product_price'},
  {name: '價錢低到高', value: 'product_price'},
];

async function fetchMany(
  {sort, paging, keyword, startDate, endDate, itemStatus},
  onSuccess,
  onFailure,
) {
  try {
    AppActions.setLoading(true);
    const resp = await YahooActions.fetchProductList(
      keyword ? {product_name: {$regex: `${keyword}`, $options: 'g'}} : {},
      [sort],
      paging,
    );
    onSuccess(resp);
  } catch (ex) {
    console.warn('API error', ex);
    onFailure();
  } finally {
    AppActions.setLoading(false);
  }
}

const makeColumns = () => [
  {title: 'id', key: 'product_id', dataIndex: 'product_id', width: 150},
  {title: 'name', key: 'product_name', dataIndex: 'product_name', width: 500},
  {
    title: 'model',
    key: 'product_model',
    dataIndex: 'product_model',
    width: 150,
  },
  {
    title: 'inventory',
    key: 'inventory',
    dataIndex: '__inventory',
    width: 100,
  },
  {
    title: 'retention',
    key: 'retention',
    dataIndex: '__retention',
    width: 100,
  },
  {
    title: 'price',
    key: 'product_price',
    dataIndex: 'product_price',
    width: 150,
  },
];

export {pageSizeOptions, sortOptions, fetchMany, makeColumns};
